

































import {
  Component,
  Prop,
  PropSync,
  Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Job from '@modules/declaration/entities/Job';
import Employee from '@modules/declaration/entities/Employee';
import EmployeeHasJob from '@modules/declaration/entities/EmployeeHasJob';
import { FirmListItem } from '@modules/declaration/types/firm-list';

const firmUpdateModule = namespace('firmUpdate');

@Component
export default class TransferJobModal extends Vue {
  @Prop({ type: Array, required: true })
  unitsList!: Array<FirmListItem>;

  @Prop({ type: Object, required: true })
  itemToMove!: Job;

  @PropSync('show')
  visible!: Boolean;

  @firmUpdateModule.Getter('getJob')
  getJob!: (uuid: string) => Job;

  @firmUpdateModule.Getter('getEmployeeWithPerson')
  getEmployee!: (uuid: string) => Employee;

  @firmUpdateModule.Getter('getCurrentEmployeeHasJobsByJob')
  getEmployeeHasJobsByJob!: (jobUuid: string) => EmployeeHasJob[];

  selectedTransferUnit: FirmListItem | string = '';

  get list() : Array<FirmListItem> {
    const list : Array<FirmListItem> = [];
    const levelScore = (level: number) => {
      let leveling = '';
      for (let i = 0; i < level; i += 1) {
        leveling += '----';
      }
      return leveling;
    };
    const recursiveUnit = (unit: FirmListItem, level: number) => {
      if (unit.type === 'unit') {
        const leveling = levelScore(level);
        unit.labelFake = `${leveling} ${unit.label}`;
        list.push(unit);
        if (unit.children && unit.children.length > 0) {
          unit.children.forEach((unitChild : FirmListItem) => {
            recursiveUnit(unitChild, level + 1);
          });
        }
      }
    };
    this.unitsList.forEach((unit : FirmListItem) => {
      recursiveUnit(unit, 0);
    });
    list.shift();
    return list;
  }

  get selectedJob(): Job | null {
    return this.getJob(this.itemToMove.uuid);
  }

  get selectedJobEmployeesNames(): Array<string> {
    if (!this.selectedJob) {
      return [];
    }

    const employeeHasJobs: EmployeeHasJob[] = this.getEmployeeHasJobsByJob(this.selectedJob.uuid);

    return employeeHasJobs.map(employeeHasJob => this.getEmployee(employeeHasJob.employeeUuid).label);
  }

  valid() {
    this.$emit('valid', { selectedUnit: this.selectedTransferUnit, job: this.selectedJob });
    this.$emit('update:show', false);
  }

  close() {
    this.$emit('update:show', false);
  }
}
